import { render, staticRenderFns } from "./TestWebRTC.vue?vue&type=template&id=9bea6044&scoped=true&"
import script from "./TestWebRTC.vue?vue&type=script&lang=js&"
export * from "./TestWebRTC.vue?vue&type=script&lang=js&"
import style0 from "./TestWebRTC.vue?vue&type=style&index=0&id=9bea6044&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bea6044",
  null
  
)

export default component.exports